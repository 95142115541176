import styles from './LandingWindow.module.sass'
import { LandingAnimationStatus as Status } from '../../../types/types.d'

type LandingWindowProps = {
  status: Status
  setStatus: React.Dispatch<React.SetStateAction<Status>>
  setFirstVisit: React.Dispatch<React.SetStateAction<boolean>>
}

export const LandingWindow: React.FC<LandingWindowProps> = ({
  status,
  setStatus,
  setFirstVisit
}) => {

  const handleAnimationEnd = () => {
    setStatus(Status.Done)
    setFirstVisit(false)
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="150 80 370 274">


      <g className={`${styles.window} ${status == Status.Done ? styles.hidden : styles.visible}`}>
        <g id="window-2">
          <path id="window-container-box-shadow" stroke="#000" strokeMiterlimit="10" strokeWidth="4.67" d="M175.67 137.5h306.15V304H175.67z" opacity=".35" />
          <g id="window-container">
            <path fill="#e5e5e5" d="M181.82 127.6h308.14v170.3H181.82z" />
            <path d="M488.92 128.65v168.21H182.86V128.65h306.06m2.08-2.08H180.77v172.38H491V126.56Z" />
          </g>
          <g id="window-top">
            <path id="line-top-left" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth=".79" d="M193 140.62h65" />
            <path id="line-bottom-left" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth=".79" d="M193 147.51h65" />
            <path id="line-top-right" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth=".79" d="M414 140.62h64" />
            <path id="line-bottom-right" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth=".79" d="M414 147.51h64" />
            <text fontFamily="Roboto-Medium,Roboto" fontSize="15.37" fontWeight="500" transform="matrix(1.26 0 0 1 265.04 147.94)"><tspan x="0" y="0" letterSpacing="-.01em">pete</tspan><tspan x="29.58" y="0" letterSpacing="-.02em">r</tspan><tspan x="34.67" y="0" letterSpacing="-.01em">g</tspan><tspan x="43.21" y="0" letterSpacing="-.02em">r</tspan><tspan x="48.31" y="0" letterSpacing="-.01em">ego</tspan><tspan x="73.51" y="0" letterSpacing="0">r</tspan><tspan x="78.88" y="0" letterSpacing="-.09em">y</tspan><tspan x="84.94" y="0" letterSpacing="-.01em">.d</tspan><tspan x="97.56" y="0" letterSpacing="-.02em">e</tspan><tspan x="105.54" y="0" letterSpacing="-.01em">v</tspan></text>
            <path id="line-divider-inside-container" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2.08" d="M181.61 161.56H491" />
          </g>
        </g>
        <g className={`${styles.startButtonGroup} ${status !== Status.Start ? styles.hidden : styles.visible}`}>
          <path className={styles.startButtonBoxShadow} stroke="#000" strokeMiterlimit="10" strokeWidth="3" d="M248.3 198.5h170v60h-170z" opacity=".35" />
          <path className={styles.startButtonBox} fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="3" d="M251.3 195.5h170v60h-170z" />
          <text className={styles.startText} fontFamily="CourierNewPS-BoldMT,&quot;Courier New&quot;" fontSize="26" fontWeight="400" letterSpacing=".01em" transform="matrix(.95 0 0 1 299 233)"><tspan x="0" y="0">START</tspan></text>
        </g>
        <g
          className={`${styles.loadingBox} ${status !== Status.Loading ? styles.hidden : styles.visible}`}
          onAnimationEnd={handleAnimationEnd}
        >
          <rect className={styles.loadingBar} width="258" height="32" x="208" y="228" fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="2" rx="3.79" ry="3.79" />
          <rect className={styles.startLoad} width="4.98" height="30" x="209" y="229" fill="#0014f4" stroke="#0014f4" strokeMiterlimit="10" strokeWidth=".12" rx="2.49" ry="2.49" />
          <text fontFamily="CourierNewPS-BoldMT,&quot;Courier New&quot;" fontSize="21.04" fontWeight="700" letterSpacing=".01em" transform="matrix(.9 0 0 1 296.36 207)"><tspan x="0" y="0">LOADING…</tspan></text>
        </g>
      </g>
    </svg>
  )
}