import { useContext, useMemo, useRef } from "react";
import styles from './Windows.module.sass'
import { initialWindowConfigMap } from "../../config/initialWindowConfig";
import useWindowSetup from "../../hooks/useWindowSetup";
import { Window } from "./windowTemplates/WindowTemplate/Window/Window";
import AppContext from "../App/AppContext";
import useCalculateWindowPositions from "../../hooks/useCalculateWindowPositions";
import { InitialWindowConfigMap, Notification } from "../../types/types";
import { DetailedNotification } from "../TopMenu/Notifications/DetailedNotification/DetailedNotification";

const useMemoizedWindowLayouts = (
  initialWindowConfigMap: InitialWindowConfigMap,
  windowsContainer: React.RefObject<HTMLDivElement>
) => {
  const { calculateWindowLayouts } = useCalculateWindowPositions(windowsContainer);
  return useMemo(
    () => calculateWindowLayouts(initialWindowConfigMap)
    , [calculateWindowLayouts, initialWindowConfigMap]
  );
};

const Windows: React.FC = () => {

  const { windows, setWindows } = useContext(AppContext);
  const windowsContainer = useRef<HTMLDivElement>(null)
  const { createWindowProps } = useWindowSetup(windowsContainer, windows, setWindows)
  const initialisedWindowValuesMap = useMemoizedWindowLayouts(initialWindowConfigMap, windowsContainer);



  return (
    <div className={styles.windowsContainer} ref={windowsContainer}>
      {
        Object.entries(initialisedWindowValuesMap).map(([windowId, initialWindowValues]) => (
          <Window
            key={windowId}
            {...createWindowProps(windowId, initialWindowValues)}
            content={initialWindowValues.content}
          />
        ))
      }
    </div>
  )
}

export default Windows